<script setup lang="ts">
import { tv } from 'tailwind-variants'
import type { PropType } from 'vue'

defineProps({
  color: {
    type: String as PropType<'rose' | 'slate'>,
    default: 'rose',
  },
  variant: {
    type: String as PropType<'solid' | 'outline'>,
    default: 'solid',
  },
  size: {
    type: String as PropType<'sm' | 'md' | 'lg'>,
    default: 'md',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const buttonClasses = tv({
  base: 'w-full font-bold rounded-md focus:outline-none focus:shadow-outline',
  variants: {
    color: {
      rose: 'bg-rose-500',
      slate: 'bg-slate-700',
    },
    size: {
      sm: 'text-xs py-1 px-4',
      md: 'text-sm py-3 px-4',
      lg: 'text-base py-4 px-5',
    },
    variant: {
      solid: 'text-white',
      outline: 'border border-rose-500 bg-transparent',
    },
    disabled: {
      true: 'opacity-50 cursor-not-allowed',
    },
  },
  compoundVariants: [
    {
      color: 'rose',
      variant: 'solid',
      class: 'bg-rose-500 text-white',
    },
    {
      color: 'rose',
      variant: 'solid',
      disabled: true,
      class: 'bg-rose-300 text-white',
    },
    {
      color: 'rose',
      variant: 'outline',
      class: 'border border-rose-500 text-rose-500',
    },
    {
      color: 'slate',
      variant: 'solid',
      class: 'bg-slate-700 text-white',
    },
    {
      color: 'slate',
      variant: 'outline',
      class: 'border border-slate-700 text-slate-700',
    },
  ],
})
</script>

<template>
  <button
    type="button"
    :class="buttonClasses({ color: color, size: size, variant: variant, disabled: disabled })"
  >
    <slot />
  </button>
</template>
